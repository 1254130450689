import React, { ReactElement, useCallback, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import tw, { styled, TwStyle } from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, Text } from '../../atom';
import Tooltip from '../Tooltip/Tooltip.molecule';

export interface Option<T> {
  label: string;
  value: T;
}

interface Props<T> {
  containerStyle?: TwStyle;
  labelContainerStyle?: TwStyle;
  dropdownOptionStyle?: TwStyle;
  dropdownOptionContainerStyle?: TwStyle;
  dropdownOptionContentStyle?: TwStyle;
  labelStyle?: TwStyle;
  selectedLabel: string;
  options: Option<T>[];
  withPortal?: boolean;
  icon?: ReactElement;
  disabled?: boolean;
  dropdownIconContainerStyle?: TwStyle;
  dropdownIconSize?: number;
  onSelected(option: Option<T>): void;
  testID?: string;
}

const LabelContainer = styled.div(
  ({ isDisabled }: { isDisabled?: boolean }) => [
    tw`flex gap-2.5`,
    isDisabled && tw`text-grey-placeholder!`,
  ],
);
const SelectContainer = styled.div(
  ({ isDisabled }: { isDisabled?: boolean }) => [
    tw`flex justify-between w-max min-w-[160px] border border-solid border-grey-four rounded-full bg-white px-3 py-0.5 cursor-pointer`,
    isDisabled && tw`text-grey-placeholder bg-neutral-50 cursor-default`,
  ],
);
const SelectLabel = styled(Text.BodyTwo)(
  ({ isDisabled }: { isDisabled?: boolean }) => [
    tw`flex flex-row items-center font-bold mr-2`,
    isDisabled && tw`text-grey-placeholder!`,
  ],
);
const SelectIcon = styled.div(
  ({ expanded, isDisabled }: { expanded?: boolean; isDisabled?: boolean }) => [
    tw`flex items-center p-1.5 -mx-1.5 rounded-full transform[rotate(90deg)] duration-200 group-hover:bg-orange-hover`,
    isDisabled &&
      tw`text-grey-placeholder! cursor-default group-hover:bg-neutral-50`,
    expanded && tw`transform[rotate(-90deg)]`,
  ],
);
const OptionLabel = tw.button`py-2 px-4 text-left rounded-lg hover:(bg-orange-hover text-orange)`;

const DropdownOptionDefaultStyle = tw`px-0 py-2.5 max-w-[220px] flex flex-col rounded-md animate-slide-in-top duration-300 transition shadow-card`;

export default function Dropdown<T>({
  containerStyle,
  labelContainerStyle,
  labelStyle,
  selectedLabel,
  options,
  dropdownOptionStyle,
  dropdownOptionContainerStyle,
  dropdownOptionContentStyle,
  withPortal = true,
  icon,
  disabled,
  dropdownIconContainerStyle,
  dropdownIconSize = 18,
  onSelected,
  testID,
}: Props<T>) {
  const ref = useRef(null);
  const [isExpanded, toggleExpanded] = useToggle(false);
  const translator = useTranslator();

  const handleOptionClick = (option: Option<T>) => {
    if (disabled) return;
    toggleExpanded();
    onSelected(option);
  };

  const handleDropdownClick = useCallback(() => {
    if (disabled) return;
    toggleExpanded();
  }, [disabled, toggleExpanded]);

  useClickAway(ref, (ev: MouseEvent) => {
    const attrValue = (ev.target as Element).getAttribute('data-testid');
    const ignoredTestIDs = [
      'Tooltip:Container:Content',
      ...options.map((_, i) => `HaulingOrder:AssignmentDropdown:Option:${i}`),
      ...options.map((_, i) => `Dashboard:BarChartDropdown:Option:${i}`),
      ...options.map((_, i) => `Dropdown:Option:${i}`),
      ...options.map((_, i) => `Dashboard:LineChartDropdown:Option:${i}`),
    ];

    // do NOT close popup whenever user click inside popup
    if (attrValue && ignoredTestIDs.includes(attrValue)) return;
    // close popup whenever user click outside bulk button
    toggleExpanded(false);
  });
  return (
    <>
      <SelectContainer
        className="group"
        isDisabled={disabled}
        onClick={handleDropdownClick}
        css={containerStyle}
        ref={ref}
      >
        <LabelContainer isDisabled={disabled} css={labelContainerStyle}>
          {icon}
          <SelectLabel isDisabled={disabled} css={labelStyle}>
            {selectedLabel}
          </SelectLabel>
        </LabelContainer>
        <SelectIcon
          isDisabled={disabled}
          expanded={isExpanded}
          css={dropdownIconContainerStyle}
        >
          <Icon.ChevronRounded
            width={dropdownIconSize}
            height={dropdownIconSize}
            strokeWidth={3}
          />
        </SelectIcon>
      </SelectContainer>

      <Tooltip
        withPortal={withPortal}
        addArrow={false}
        offset={[0, 10]}
        variant="light"
        placement="bottom-end"
        targetRef={ref}
        visible={isExpanded}
        containerStyle={dropdownOptionContainerStyle}
        contentStyle={dropdownOptionContentStyle ?? DropdownOptionDefaultStyle}
      >
        {options.map((option, i) => (
          <OptionLabel
            type="button"
            key={option.label}
            data-testid={`${testID || ''}Dropdown:Option:${i}`}
            onClick={() => handleOptionClick(option)}
            css={dropdownOptionStyle}
          >
            {translator.translate(option.label)}
          </OptionLabel>
        ))}
      </Tooltip>
    </>
  );
}
