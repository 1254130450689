import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import capitalize from 'lodash/capitalize';
import isEqual from 'lodash/isEqual';
import truncate from 'lodash/truncate';
import { read, utils, WorkSheet, writeFileXLSX } from 'xlsx';
import * as yup from 'yup';
import store from '../app/store/store.app';
import { SnackbarTheme } from '../component/molecule/Snackbar/Snackbar.molecule';
import localeConfig, {
  SupportedLanguage,
} from '../config/locale/locale.config';
import {
  BulkActionStatusEnum,
  RedirectUrl,
  RedirectUrlTarget,
} from '../constant';
import { UseTranslator } from '../hook/useTranslator.hook';
import { Photo } from '../model/Common.model';
import { ErrorDetail } from '../service/api.endpoint';
import { snackbarAction } from '../store/snackbar.store';
import { getFullDateTimeFormat } from './date.util';
/**
 * Gets the language used by the user in their OS Settings
 * and returns it if the language is listed on the `Availability` list.
 * If not then returns the default language, which is currently `en`.
 * @returns Default language used for the application
 */
export function getDefaultLang(): string {
  const localeString = navigator.language || '';
  const languageString = localeString.split(/[_-]/)[0].toLowerCase();
  const checkLang = Object.values(SupportedLanguage).findIndex(
    (item) => item === languageString,
  );

  return checkLang > 0 ? languageString : 'id';
}

/**
 * This helper is for mock data
 * By default this will generate 100 to 1000 or pass object for customize it
 * @param param0
 * @returns
 */
export const generateRandNumFrom100to1000 = (params?: {
  from: number;
  to: number;
}): number => {
  const from = params?.from || 10;
  const to = params?.to || 1000;
  return Math.floor(Math.random() * to) + from;
};

/**
 * Input a Date type to get
 * dd-mm-yyyy string
 * @param date
 */
export function getDigitFormatDate(date: Date): string {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [day, month, year].join('-');
}

export function getLongFormatDate(
  date: Date,
  locale?: string,
  showTime?: boolean,
): string {
  const d = new Date(date);
  const options: Intl.DateTimeFormatOptions = { month: 'long' };
  const day = `${d.getDate()}`;
  const month = new Intl.DateTimeFormat(locale || 'en-US', options).format(d);
  const year = d.getFullYear();
  let hour = `${d.getHours()}`;
  let minutes = `${d.getMinutes()}`;

  if (hour.length < 2) hour = `0${hour}`;
  if (minutes.length < 2) minutes = `0${minutes}`;

  const formattedDate = [day, month, year].join(' ');
  if (showTime) return `${formattedDate}, ${hour}:${minutes}`;
  return formattedDate;
}

export function getShortFormatDate(
  date: Date,
  locale?: string,
  showTime?: boolean,
): string {
  const d = new Date(date);
  const options: Intl.DateTimeFormatOptions = { month: 'short' };
  let day = `${d.getDate()}`;
  const month = new Intl.DateTimeFormat(locale || 'en-US', options).format(d);
  const year = d.getFullYear();

  let hour = `${d.getHours()}`;
  let minutes = `${d.getMinutes()}`;

  if (day.length < 2) day = `0${day}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minutes.length < 2) minutes = `0${minutes}`;

  const formattedDate = [day, month, year].join(' ');
  if (showTime) return `${formattedDate}, ${hour}:${minutes}`;
  return formattedDate;
}
export function getDateFromString(dateStr: string): Date | null {
  if (dateStr) {
    const [day, month, year] = dateStr.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }
  return null;
}

/**
 * Turns Date into short format date string or empty string
 * @param date
 */
export function inputValue(date?: Date | null): string | undefined {
  if (date) {
    return getShortFormatDate(date);
  }
  return undefined;
}

/**
 *
 * @param object
 * @returns
 */
export const toCamelCase = <T>(object: unknown): T => {
  let transformedObject = object as Record<string, unknown>;
  if (typeof object === 'object' && object !== null) {
    if (Array.isArray(object)) {
      transformedObject = object.map(toCamelCase) as unknown as Record<
        string,
        unknown
      >;
    } else {
      transformedObject = {};
      for (const key of Object.keys(object)) {
        if ((object as Record<string, unknown>)[key] !== undefined) {
          const firstUnderscore = key.replace(/^_/, '');
          const newKey = firstUnderscore.replace(/(_\w)|(-\w)/g, (k) =>
            k[1].toUpperCase(),
          );
          transformedObject[newKey] = toCamelCase(
            (object as Record<string, unknown>)[key],
          );
        }
      }
    }
  }
  return transformedObject as T;
};

/**
 *
 * @param object
 * @returns
 */
export const toSnakeCase = <T>(object: unknown): T => {
  let transformedObject = object as Record<string, unknown>;
  if (typeof object === 'object' && object !== null) {
    if (Array.isArray(object)) {
      transformedObject = object.map(toSnakeCase) as unknown as Record<
        string,
        unknown
      >;
    } else {
      transformedObject = {};
      for (const key of Object.keys(object)) {
        if ((object as Record<string, unknown>)[key] !== undefined) {
          const newKey = key
            .replace(
              /\.?([A-Z]+)/g,
              (_, y) => `_${y ? (y as string).toLowerCase() : ''}`,
            )
            .replace(/^_/, '');
          transformedObject[newKey] = toSnakeCase(
            (object as Record<string, unknown>)[key],
          );
        }
      }
    }
  }
  return transformedObject as T;
};

/**
 *
 * @param value
 * @returns
 */
export const getNumericValue = (value: string) => value.replace(/\D/g, '');
/**
 *
 * @param value
 * @returns
 */
export const getLatValue = (value: string) =>
  value.replace(/^[-]?([0-8]?\d(\.\d{1,7})?|90(\.0{1,7})?)$/g, '');

/**
 *
 * @param value
 * @returns
 */
export const getFloatingValue = (value: string) => {
  if (value.indexOf(',') !== -1) {
    const decimals = value.substring(value.indexOf(',') + 1);
    const wholeNumber = value.substring(0, value.indexOf(','));

    return [
      getNumericValue(wholeNumber) || 0,
      getNumericValue(decimals).slice(0, 2),
    ].join(',');
  }

  return getNumericValue(value);
};

/**
 * Remove trailing dot in unfinished float number
 * @param value
 * @returns
 */
export const removeTrailingDot = (value: string) => {
  if (/(\D)$/i.test(value)) {
    return getNumericValue(value);
  }
  return value;
};

/**
 * Remove leading whitespaces
 * @param value
 * @returns
 */
export const removeLeadingWhitespace = (value?: string) => {
  if (!value) return '';
  if (/^[\s]*$/i.test(value)) {
    return value.replace(/^[\s]*/i, '');
  }
  return value;
};

/**
 * Remove leading whitespaces
 * @param value
 * @returns
 */
export const removeMultipleWhitespaceBetweenWords = (value?: string) => {
  if (!value) return '';
  if (/(\s){2,}/g.test(value)) {
    return value.replace(/(\s){2,}/g, ' ');
  }
  return value;
};

/**
 * Remove leading zero
 * @param value
 * @returns
 */
export const removeLeadingZeros = (value: string) => {
  if (/^([0]{1,})([1-9]{1,})/i.test(value)) {
    return value.replace(/^(0)/i, '');
  }
  return value.replace(/^[0]{2,}/i, '0');
};

export const createWrapperAndAppendToBody = (
  wrapperId: string,
  className?: string,
) => {
  const wrapperElement = document.createElement('div');
  if (className) wrapperElement.setAttribute('class', className);
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

/**
 * Returns the initials for the name supplied.
 * @param fullName Full Name that you want to get the Initials of.
 */
export function getInitialFromName(fullName?: string): string {
  if (!fullName) return '';
  const initials = fullName.trimEnd().split(' ');
  const initialData = initials[initials.length - 1]?.charAt(0);
  return `${initials[0].charAt(0)}${
    initials.length > 1 ? initialData : ''
  }`.toUpperCase();
}

/**
 * Wrapper helper for promise function using IIFE format.
 * @param callback callback function that you want to wrap.
 */
export const promiseToVoid = (callbacks: Promise<unknown>[]) => {
  for (const callback of callbacks) {
    void (async () => {
      await callback;
    })();
  }
};
/*
 * Redirect user based on provided parameters
 * @param redirectUrl
 */
export function doRedirectUrl({
  url,
  target = RedirectUrlTarget.SELF,
}: RedirectUrl): void {
  if (target === RedirectUrlTarget.SELF) {
    window.location.href = url;
  } else {
    window.open(url, target);
  }
}

/**
 * yup contact number validation
 * @param fieldName
 * @returns
 */
export function validateContactNumber(fieldName: string) {
  return (value?: string) => {
    if (!value) return true;
    if (/^8/.test(value)) {
      return /(^8\d{8,11}$)/g.test(value)
        ? true
        : new yup.ValidationError(
            'Mobile number is invalid.',
            value,
            fieldName,
          );
    }

    return /(^\d{2,3}\d{6,9}$)/g.test(value)
      ? true
      : new yup.ValidationError(
          'Telephone number is invalid.',
          value,
          fieldName,
        );
  };
}

export const removeNonNumericString = (value: string) =>
  value.replace(/\D/g, '');

/**
 * yup Phone number validation
 * @param fieldName
 * @returns
 */
export function validatePhoneNumber(fieldName: string) {
  return (value?: string) => {
    if (!value) return true;
    const cleanedNumber = removeNonNumericString(value);
    if (/^8/.test(cleanedNumber)) {
      if (/(^8\d{8,11}$)/g.test(cleanedNumber)) {
        return true;
      }
      if (cleanedNumber.length < 9) {
        return new yup.ValidationError(
          'Phone number must be at least 9 digits.',
          cleanedNumber,
          fieldName,
        );
      }
      if (cleanedNumber.length > 12) {
        return new yup.ValidationError(
          'Phone number maximum 12 digits.',
          cleanedNumber,
          fieldName,
        );
      }
      return new yup.ValidationError(
        'Phone number is invalid.',
        cleanedNumber,
        fieldName,
      );
    }
    return new yup.ValidationError(
      'Phone number start with 8.',
      cleanedNumber,
      fieldName,
    );
  };
}

/**
 * Map table bulk action status to labeled string
 * @param {BulkActionStatusEnum} status - The status "WAITING" | "SUCCESS" | "FAILED"
 * @returns {string} status label
 */
export function mapBulkActionStatusToLabel(
  status: BulkActionStatusEnum,
): string {
  switch (status) {
    case BulkActionStatusEnum.FAILED:
      return 'Failed';
    case BulkActionStatusEnum.SUCCEED:
      return 'Succeed';
    default:
      return 'Waiting';
  }
}

/**
 * Replace plus simbol in URL to proper hex code, so the `decodeURIComponent` will correctly parse the given value
 * @param value
 * @returns
 */
export function replacePlusSign(value: string) {
  return value.replace(/\+/g, '%20');
}

/**
 * This will works with some rules:
 * 1. If the file source located in the same origin as the application.
 * 2. If the file source is on different location e.g s3 bucket, etc. Set the response headers `Content-Disposition: attachment`.
 * Otherwise it only view on new tab.
 * @param url
 * @returns void
 */
export function doDownload(url: string): void {
  if (!url) return;
  const link = document.createElement('a');
  link.href = url;
  link.download = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Translate function to be used outside react component
 * @param key
 * @param currentLanguage
 * @returns
 */
export function toTranslate(key: string, currentLanguage: SupportedLanguage) {
  const translationIndex = localeConfig.findIndex((find) => find.en === key);

  if (
    translationIndex >= 0 &&
    localeConfig[translationIndex][currentLanguage] !== null
  ) {
    return localeConfig[translationIndex][currentLanguage] || key;
  }

  return key;
}

export function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

/**
 * compare 2 form value and return boolean
 * @param formData
 * @param remoteData
 * @returns
 */
export function isFormChanged<T>(formData: T, remoteData?: T): boolean {
  if (!remoteData) return false;
  return !Object.keys(formData as unknown as string)
    .map((v) => {
      const key = v as keyof T;
      if (typeof formData[key] === 'object')
        return isEqual(formData[key], remoteData[key]);
      return (
        String(formData[key])?.toString().toLowerCase() ===
        String(remoteData[key])?.toString().toLowerCase()
      );
    })
    .every((v) => !!v);
}

/**
 * check pin code and return condition
 * @param pin
 * @returns
 */

export function pinCodeChecker(pin: string): {
  isValid: boolean;
  isPinConsecutives?: boolean;
  isPinSameCharacters?: boolean;
  isPinPatternRepeat?: boolean;
} {
  let isValid = false;
  if (pin.length !== 6) return { isValid };

  const patternInc = '0123456789';
  const patternDec = '9876543210';

  const isConsecutivesInc = patternInc.includes(pin);
  const isConsecutivesDec = patternDec.includes(pin);
  const isSameCharacters = /(\d)\1{5}/g.test(pin);

  isValid = !isConsecutivesDec && !isConsecutivesInc && !isSameCharacters;

  return {
    isValid,
    isPinConsecutives: isConsecutivesInc || isConsecutivesDec,
    isPinSameCharacters: isSameCharacters,
    isPinPatternRepeat: isSameCharacters,
  };
}

/**
 * validate pin with condition cannot increment, cannot same 6 digit
 * @param pin
 * @returns
 */
export function isPinCodeValid(pin: string) {
  return pinCodeChecker(pin).isValid;
}

/**
 * generate 6 digit pins within the validate condition
 * @param pin
 * @returns
 */
export function generatePin(pin = '000000') {
  let generatedPin = pin;
  if (!isPinCodeValid(generatedPin)) {
    generatedPin = generatePin(
      Math.floor(100000 + Math.random() * 900000).toString(),
    );
  }
  return generatedPin;
}

/**
 * yup driver pin validation
 * @param fieldName
 * @returns
 */
export function validateDriverPin(fieldName: string) {
  return (value?: string) => {
    if (!value)
      return new yup.ValidationError('PIN is required.', value, fieldName);

    const pinCondition = pinCodeChecker(value);
    if (pinCondition.isValid) return true;
    if (pinCondition.isPinConsecutives) {
      return new yup.ValidationError(
        'Consecutive digit is not allowed.',
        value,
        fieldName,
      );
    }
    if (pinCondition.isPinPatternRepeat || pinCondition.isPinSameCharacters) {
      return new yup.ValidationError(
        'Repeat pattern is not allowed.',
        value,
        fieldName,
      );
    }
    return new yup.ValidationError('PIN is not valid.', value, fieldName);
  };
}

export function copyToClipboard(data: string) {
  void navigator.clipboard.writeText(data);
}

/**
 * Converts an array of objects of tag types to an array of tag types
 * @param tagsObjects Array of objects with tags
 * @returns string of tag types
 */
export function generateTagsArray(
  ...tagsObjects: Record<string, string>[]
): string[] {
  const tags: string[] = [];

  for (const tagsObject of tagsObjects) {
    for (const tag of Object.values(tagsObject)) {
      // Don't use falsey values
      if (tag) {
        tags.push(tag);
      }
    }
  }

  return tags;
}

export async function getBinaryContent({
  path,
  onSuccess,
}: {
  path: string;
  onSuccess: (data: string | ArrayBuffer) => Promise<void>;
}) {
  try {
    const res = await fetch(`${path}?x-request=xhr`);
    const data = await res?.arrayBuffer();
    if (data) await onSuccess(data);
  } catch (err) {
    throw new Error(err as string);
  }
}

export async function doDownloadAndExtractAllPhotos(
  photos: Photo[],
  translator: UseTranslator,
  zipName = 'zipImage',
) {
  const zip = JSZip();

  if (!window.navigator.onLine) {
    store.dispatch(
      snackbarAction.show({
        type: SnackbarTheme.warning,
        message: translator.translate('No internet connection'),
      }),
    );
    return;
  }
  if (!photos.length) return;

  for (let i = 0; i < photos.length; i += 1) {
    try {
      await getBinaryContent({
        path: photos[i].accessUrl,
        onSuccess: async (data) => {
          try {
            zip.file(photos[i].fileName, data, { binary: true });
            if (i === photos.length - 1) {
              await zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, zipName);
              });
            }
          } catch (err) {
            if (typeof err === 'string') {
              store.dispatch(
                snackbarAction.show({
                  type: SnackbarTheme.warning,
                  message: err,
                }),
              );
            }
          }
        },
      });
    } catch (err) {
      store.dispatch(
        snackbarAction.show({
          type: SnackbarTheme.warning,
          message: translator.translate('Download Process Failed'),
        }),
      );
    }
  }
}

/**
 *
 * @param value
 * @returns
 */
export const stringDecimalFormatter = (value?: number | string) => {
  if (!value) return 0;
  return Number(String(value).replace(/\./g, '').replace(',', '.'));
};

export const isOldSafari = () => {
  const userAgentData = navigator.userAgent.split('/');
  if (!userAgentData[3]?.includes('Safari')) return false;
  return parseFloat(userAgentData[3]?.replace(' Safari', '')) < 15.0;
};
/**
 * @param email
 * @returns
 * @description This may not covers all email pattern, in case that happen you need to update the regex
 */
export const validateEmail = (email: string) => {
  const regex =
    /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?([_-][a-zA-Z0-9]+)*$/;
  return regex.test(email);
};

export const generateArrayUrlParams = (
  data?: Record<string, string | undefined>,
): string => {
  if (!data) return '';
  const ObjVal = Object.values(data);
  const ObjKey = Object.keys(data);
  if (!ObjVal[0] && !ObjKey[0]) return '';
  let urlParams = '';

  for (const [index, item] of ObjVal.entries()) {
    const formattedItem = item?.split(',');

    if (formattedItem) {
      for (const [i, val] of formattedItem.entries()) {
        urlParams += `${ObjKey[index]}=${val}${
          i !== formattedItem.length - 1 ? '&' : ''
        }`;
      }
    }

    urlParams += `${index !== ObjVal.length - 1 ? '&' : ''}`;
  }

  return urlParams;
};

export const getDisabledTimeRange = (
  soDate?: Date,
  currentDate?: Date,
): { from: string; to: string } | undefined => {
  if (!soDate || !currentDate) return undefined;
  if (getShortFormatDate(soDate) !== getShortFormatDate(currentDate))
    return undefined;
  const from = '24:00';
  const to = format(addMinutes(soDate, 1), 'HH:mm');
  return { from, to };
};

/**
 * Sorts an array of items into groups.
 * The return value is a map where the keys are the group ids the given `getGroupId` function produced and the value is an array of each item in that group.
 *
 * @example
 *
 * ```ts
 * const jo = [
 *   {
 *     number: 'JO-001',
 *     status: 'ASSIGNED'
 *   },
 *   {
 *     number: 'JO-002',
 *     status: 'DELIVERED'
 *   },
 *   {
 *     number: 'JO-003',
 *     status: 'DELIVERED'
 *   }
 * ]
 *
 * const joBystatus = group(jo, val => val.status)
 * // => {
 *   ASSIGNED: [{
 *     number: 'JO-001',
 *     status: 'ASSIGNED'
 *   }],
 *   DELIVERED: [{
 *     number: 'JO-002',
 *     status: 'DELIVERED'
 *   }, {
 *     number: 'JO-003',
 *     status: 'DELIVERED'
 *   }]
 * }
 * ```
 */
export const group = <T, Key extends string | number | symbol>(
  array: readonly T[],
  getGroupId: (item: T) => Key,
): Partial<Record<Key, T[]>> =>
  array.reduce(
    (acc, item) => {
      const groupId = getGroupId(item);

      if (!acc[groupId]) acc[groupId] = [];
      acc[groupId].push(item);

      return acc;
    },
    {} as Record<Key, T[]>,
  );

/**
 * If the item matching the condition already exists in the list it will be removed.
 * If it does not it will be added.
 *
 * @example
 *
 * ```ts
 * const ra = { name: 'Ra' }
 * const zeus = { name: 'Zeus' }
 * const loki = { name: 'Loki' }
 * const vishnu = { name: 'Vishnu' }
 *
 * const gods = [ra, zeus, loki]
 *
 * toggle(gods, ra, g => g.name)     // => [zeus, loki]
 * toggle(gods, vishnu, g => g.name) // => [ra, zeus, loki, vishnu]
 * ```
 */
export const toggle = <T>(
  list: readonly T[],
  item: T,
  /**
   * Converts an item of type T item into a value that
   * can be checked for equality
   */
  toKey?: null | ((item: T, idx: number) => number | string | symbol),
  options?: {
    strategy?: 'prepend' | 'append';
  },
) => {
  if (!list && !item) return [];
  if (!list) return [item];
  if (!item) return [...list];

  const matcher = toKey
    ? (x: T, idx: number) => toKey(x, idx) === toKey(item, idx)
    : (x: T) => x === item;
  const existing = list.find(matcher);

  if (existing) return list.filter((x, idx) => !matcher(x, idx));

  const strategy = options?.strategy ?? 'append';

  if (strategy === 'append') return [...list, item];

  return [item, ...list];
};

/**
 * Utils for share text via wa
 * @param {string} phoneNumber - target phone number
 * @param {string} text - prefilled text to send
 */
export function shareToWhatsapp({
  phoneNumber,
  text,
}: { phoneNumber: string; text: string }) {
  doRedirectUrl({
    url: `https://wa.me/${phoneNumber}?text=${text}`,
    target: RedirectUrlTarget.BLANK,
  });
}

/**
 * Read file input using `FileReader` and `xlsx` library
 */
export const readXlsx = ({
  xlsData,
  sheetNumber = 0,
  callback,
}: {
  xlsData: Blob;
  sheetNumber?: number;
  callback: (worksheet: WorkSheet, data: unknown[]) => void;
}): void => {
  const fileReader = new FileReader();

  fileReader.onload = (e): void => {
    const data = e.target?.result;
    const workbook = read(data, { type: 'binary' }); // OR `cellDates: true` to read as Date
    const worksheet = workbook.Sheets[workbook.SheetNames[sheetNumber]]; // get the worksheet on specific number (default is 0)
    const jsonData = utils.sheet_to_json(worksheet); // generate json, without first row / column data
    callback(worksheet, jsonData);
  };
  fileReader.readAsBinaryString(xlsData);
};

/**
 * Write list data to `.xlsx` file
 */
export const writeXlsx = <T>(
  list: T[],
  sheetName: string,
  fileName: string,
): void => {
  const worksheet = utils.json_to_sheet(list);
  const workbook = {
    Sheets: {
      sheetName: worksheet,
    },
    SheetNames: [sheetName],
  };
  writeFileXLSX(workbook, fileName);
};

/**
 * Create columns (in A1-style) and rows from worksheet
 */
export const worksheetToRowColumn = (ws: WorkSheet) => {
  // create an array of arrays
  const rows = utils.sheet_to_json(ws, { header: 1 });

  // create column array
  const range = utils.decode_range(ws['!ref'] || 'A1');
  const columns = Array.from({ length: range.e.c + 1 }, (_, i) => ({
    key: i, // 0, 1, etc
    name: utils.encode_col(i), // the column labels will be A, B, etc
  }));
  return { rows, columns };
};

/**
 * Safely access deep values in an object via a string path seperated by `.`
 *
 * @param obj {Record<string, unknown>} - The object to parse
 * @param path {string} - The path to search in the object
 * @param [defaultValue] {unknown} -  A default value if the path doesn't exist in the object
 * @returns {any} The value if found, the default provided value if set and not found, undefined otherwise
 *
 * @example
 *
 * ```js
 * const obj = { a: { b : { c: 'hello' } } };
 *
 * const value = deepReadObject(obj, 'a.b.c');
 * // => 'hello'
 * const notFound = deepReadObject(obj, 'a.b.d');
 * // => undefined
 * const notFound = deepReadObject(obj, 'a.b.d', 'not found');
 * // => 'not found'
 * ```
 */
export const deepReadObject = <T = unknown>(
  obj: Record<string, unknown>,
  path: string,
  defaultValue?: unknown,
): T => {
  const value = path
    .trim()
    .split('.')
    // biome-ignore lint/suspicious/noExplicitAny: on purpose
    .reduce<any>((a, b) => (a ? a[b] : undefined), obj);

  return value !== undefined ? value : defaultValue;
};

/**
 * Provided a string template it will replace dynamics parts in place of variables.
 *
 * @param str {string} - The string you wish to use as template
 * @param params {Record<string, string>} - The params to inject into the template
 * @param regex {RegExp} - The RegExp used to find and replace. Default to `/{{(.*?)}}/g`
 * @returns {string} - The fully injected template
 *
 * @example
 * ```ts
 * const txt = template('Hello {{ name }}', { name: 'Tom' });
 * // => 'Hello Tom'
 * ```
 */
export const template = (
  str: string,
  params: Record<string, string>,
  regex = /{{(.*?)}}/g,
): string =>
  str.replace(regex, (_, key: string) => {
    const deepObjectValue = deepReadObject(params, key); // we can also set defaultValue to '', to replace unspecifed `params` key to ''
    return (deepObjectValue as string) ?? key;
  });

/**
 * Format snake cased string to capitalized
 *
 * @example
 * ```ts
 * const txt = 'phone_number';
 * // => 'Phone Number'
 * ```
 */
export const snakeCasedToReadableFormat = (key: string): string => {
  const splitted = key.split('_');
  const capitalized = splitted.map((val) => capitalize(val));

  return capitalized.join(' ');
};

/**
 * Map error messages to string
 */
export const mapErrorMessages = (
  translator: UseTranslator,
  errorDetails: ErrorDetail[],
) => {
  const errorMessages: string[] = [];

  for (const { key } of errorDetails) {
    const label = 'Invalid {{label}}';
    const templated = template(label, {
      label: snakeCasedToReadableFormat(key as string),
    }); // e.g. output => Invalid License Number
    errorMessages.push(translator.translate(templated));
  }

  return errorMessages.join(', ');
};

/**
 * Get Long format date from unix
 */
export function getDateFromUnix(
  unix?: number | null,
  locale?: string,
  isShort?: boolean,
  showTime?: boolean,
) {
  if (isShort)
    return getShortFormatDate(new Date((unix || 0) * 1000), locale, showTime);
  return getLongFormatDate(new Date((unix || 0) * 1000), locale, showTime);
}

/**
 * return value to unixtimeepoch with offset timezone
 */

export function getOffsettedUnixTime(number?: number | null): number {
  const offsetTime = fromUnixTime(number || 0).getTimezoneOffset();
  const val = (number || 0) + offsetTime * -60;
  return val;
}

export const generateCommonGalleryCaption = (
  fileName: string,
  createdAt: number,
) => {
  const date = getFullDateTimeFormat(fromUnixTime(createdAt), true);
  const name = truncate(fileName, { length: 50 });

  if (!fileName) return date;

  return [name, date].join(' • ');
};

export const generateGalleryWithPhotoCaption = (
  caption: string,
  createdAt: number,
) => {
  const date = getFullDateTimeFormat(fromUnixTime(createdAt), true);
  const truncatedCaption = truncate(caption, { length: 50 });

  if (!caption) return date;

  return [truncatedCaption, date].join(' • ');
};

/**
 * Array mapper Photo to Image for gallery
 * @param param0
 * @returns
 */
export const imageWithoutTitleSelector = ({
  accessUrl,
  fileName,
  createdAt,
}: Photo) => ({
  source: accessUrl,
  caption: generateCommonGalleryCaption(fileName, createdAt),
});

/**
 * Enum Value mapper
 * @param enums
 * @returns
 */
export const enumValueMapper = <T>(enums: T): string[] =>
  (Object.values(enums) as Array<keyof typeof enums>).map(
    (item) => item as string,
  );

/**
 * convert a camel-cased string to a space-separated, title-cased string
 */
export function convertCamelToTitle(camelCaseString: string): string {
  // Add a space before each capital letter using a regular expression
  const titleCaseString = camelCaseString.replace(/([A-Z])/g, ' $1');

  // Capitalize the first letter and remove leading space
  return titleCaseString.charAt(0).toUpperCase() + titleCaseString.slice(1);
}

/**
 * create photo object from url
 */
export function urlToPhoto(url: string): Photo {
  return {
    id: url,
    mimeType: 'image/png',
    accessUrl: url,
    fileName: '',
    size: 10,
    createdAt: 0,
  };
}

/**
 * replace a character at a specific index in a string
 */
export function replaceCharacter(
  text: string,
  index: number,
  replacement: string,
) {
  return (
    text.slice(0, index) + replacement + text.slice(index + replacement.length)
  );
}

/**
 * get filter label and count
 */
export function getLabelAndCountFromFilterLabel(filterLabel: string): {
  label: string;
  count?: string;
} {
  let label = filterLabel;
  let count = undefined;

  if (filterLabel.includes('Status:')) {
    const labelArr = filterLabel.split(':');

    label = labelArr[0];
    count = labelArr[1];
  }
  return { label, count };
}

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
