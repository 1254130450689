import { JOAssignmentBy } from '../constant/JOAssignment.constant';
import { AutocompleteType } from '../hook/useAutocomplete.hook';
import {
  CommonApiListParams,
  CommonApiMetadata,
  CommonApiResponse,
} from '../service/api.endpoint';
import { HaulingOrderDeliveryTargetUnit } from '../util/haulingOrderCreate.util';
import { Driver } from './Driver.model';
import { HaulingOrderTracking } from './HaulingOrderTracking.model';
import { Location } from './Location.model';
import { Schedule } from './Schedule.model';
import { Shipper } from './Shipper.model';
import { Vehicle } from './Vehicle.model';

export interface HaulingOrderPickupCommandProps {
  locationId: string;
  goods?: string;
  notes?: string;
}

export interface HaulingOrderAssignmentCommandProps {
  vehicleId?: string;
  driverId?: string;
}

export interface HaulingOrderDeliveryCommandProps {
  index: number;
  locationId: string;
  goodsTarget?: number;
  targetUnit: HaulingOrderDeliveryTargetUnit;
  assignments?: HaulingOrderAssignmentCommandProps[];
}

export interface HaulingOrderAssignmentCommandFormProps
  extends HaulingOrderAssignmentCommandProps {
  vehicleOption?: Vehicle;
  driverOption?: AutocompleteType;
  schedule?: Schedule;
  scheduleOption?: AutocompleteType;
  deliveryIndex?: number;
  isUseAssignment?: boolean;
  isCollapsed?: boolean;
  assignmentType?: JOAssignmentBy;
}
export interface HaulingOrderDeliveryCommandFormProps
  extends Omit<HaulingOrderDeliveryCommandProps, 'assignments'> {
  targetUnitOption?: AutocompleteType;
  locationOption?: AutocompleteType;
  assignments?: HaulingOrderAssignmentCommandFormProps[];
}

export interface HaulingOrderCreateForm {
  hoDate: number;
  hoNumber?: string;
  referenceNumber?: string;
  shipperId?: string;
  shipperOption?: AutocompleteType;
  pickupLocationId?: string;
  pickupLocationOption?: AutocompleteType;
  pickupGoods?: string;
  pickupNotes?: string;
  deliveries?: HaulingOrderDeliveryCommandFormProps[];
}

// #region ENDPOINT
export interface HaulingOrderCreateParam {
  hoDate: number;
  hoNumber?: string;
  referenceNumber?: string;
  shipperId?: string;
  pickup?: HaulingOrderPickupCommandProps;
  deliveries?: HaulingOrderDeliveryCommandProps[];
}

export type HaulingOrderCreateResponse = CommonApiResponse & {
  haulingOrder: {
    id: string;
    number: string;
    trackingCode: string;
  };
};
// #endregion

export enum HaulingOrderStatusEnum {
  IN_PROCESS = 101,
  ASSIGNED = 201,
  DELIVERING = 301,
  DELIVERED = 401,
}

export enum HaulingOrderDeliveryAssignmentStatusEnum {
  ASSIGNED = 201,
  DELIVERING = 301,
  DELIVERED = 401,
}

export enum HaulingOrderDeliveryTargetUnitEnum {
  QUANTITY = 'QUANTITY',
  WEIGHT = 'WEIGHT',
  VOLUME = 'VOLUME',
}

export enum HaulingOrderFilterOrderByEnum {
  UPDATED_AT_DESC = 'UPDATED_AT_DESC', // Newest Updates
  UPDATED_AT_ASC = 'UPDATED_AT_ASC', // Oldest Updates
  HAULING_ORDER_DATE_ASC = 'HAULING_ORDER_DATE_ASC', // HO Date A-Z
  HAULING_ORDER_DATE_DESC = 'HAULING_ORDER_DATE_DESC', // HO Date Z-A
  TARGET_FULLFILMENT_ASC = 'TARGET_FULLFILMENT_ASC', // Goods Target A-Z
  TARGET_FULLFILMENT_DESC = 'TARGET_FULLFILMENT_DESC', // Goods Target Z-A
}

export type HaulingOrder = {
  id: string;
  orgId?: string;
  hoDate: number; // in seconds
  hoNumber: string;
  shipperId: string;
  shipper: Shipper;
  status: HaulingOrderStatusEnum;
  statusText: keyof typeof HaulingOrderStatusEnum;
  pickup: HaulingOrderPickup;
  deliveries: HaulingOrderDelivery[];
  totalTarget: number;
  totalCompletedTarget: number;

  completedPercentage?: number | null;
  referenceNumber?: string;
  startedAt?: number; // in seconds
  completedAt?: number; // in seconds
  completedBy?: string;
  completedMethod?: string;

  trackingCode?: string;
};

export interface HaulingOrderPickup {
  id: string;
  locationId: string;
  location: Location;
  goods?: string;
  notes?: string;
}

export type HaulingOrderDelivery = {
  id: string;
  hoId: string;
  locationId: string;
  location: Location;
  index: number;
  target: number;
  targetUnit: HaulingOrderDeliveryTargetUnitEnum;
  currentTarget: number;
  assignments: HaulingOrderDeliveryAssignment[];
  assignmentsCount: number;
  completedAssignments: number;
};

export interface HaulingOrderPickupInfo {
  id: string;
  locationId: string;
  location: Location;
  goods: string;
  notes: string;
}
export interface HaulingOrderInfo {
  id: string;
  hoDate: number;
  hoNumber: string;
  referenceNumber?: string;
  shipperId: string;
  status: HaulingOrderStatusEnum;
  statusText: string;
  startedAt?: number;
  completedAt?: number;
  completed_by?: string;
  completedMethod?: string;
  pickup: HaulingOrderPickupInfo;
}

export type HaulingOrderDeliveryAssignment = {
  id: string;
  hoDeliveryId: string;
  assignmentNumber: string;
  vehicleId: string;
  vehicle: Vehicle;
  driverId: string;
  driver: Driver;
  status: HaulingOrderStatusEnum;
  createdAt: number; // in seconds
  travelExpense?: number;
  goodsIn?: number;
  goodsOut?: number;
  goodsUnit?: HaulingOrderDeliveryTargetUnitEnum;
  startedAt?: number; // in seconds
  completedAt?: number; // in seconds
  finishedBy?: string;
  finishingMethod?: string;
  notes?: string;
};

export type HaulingOrderSearchParam = {
  page?: string;
  pageSize?: string;
  search?: string;
  orderBy?: string; // string of HaulingOrderFilterOrderByEnum collection
  status?: string; // string of JOStatus collection => 'CANCELLED,DRAFT,DELETED'
  shipperId?: string;
  startDate?: string; // milliseconds
  endDate?: string; // milliseconds
};

export type HaulingOrderDeliveryInfo = {
  id: string;
  hoId: string;
  hauling_order: HaulingOrder;
  locationId: string;
  location: Location;
  index: number;
  target: number;
  targetUnit: HaulingOrderDeliveryTargetUnit;
  currentTarget: number;
  assignmentsCount: number;
  completedAssignments: number;
  assignments: HaulingOrderDeliveryAssignment[];
};

export type HOTrackingGoods = {
  number: number;
  unit: HaulingOrderDeliveryTargetUnitEnum;
};

export type HOTrackingTimelineItemDataPhoto = {
  id: string;
  url: string;
};

export type HOTrackingTimelineItemData = {
  hoDeliveryId: string;
  causedBy: 'user' | 'driver';
  driverId: string;
  goodsOut: number;
  goodsUnit: HaulingOrderDeliveryTargetUnitEnum;
  hoDeliveryAssignmentId: string;
  photos: HOTrackingTimelineItemDataPhoto[];
  locationName: string;
  driverName: string;
};

export type HOTrackingTimelineCommonItem = {
  time: number;
  type: 'COMPLETED' | 'DELIVERING' | 'IN_PROCESS';
  data: null;
};

export type HOTrackingTimelineDeliveryAssignmentCompletedItem = {
  time: number;
  type: 'DELIVERY_ASSIGNMENT_COMPLETED';
  data: HOTrackingTimelineItemData;
};

export type HOTrackingTimelineDeliveryCompletedItem = {
  time: number;
  type: 'DELIVERY_COMPLETED';
  data: Pick<
    HOTrackingTimelineItemData,
    'goodsOut' | 'goodsUnit' | 'hoDeliveryId' | 'locationName'
  >;
};

export type HOTrackingDeliveriesItem = {
  id: string;
  location: Pick<Location, 'name'>;
  target: number;
  currentTarget: number;
  targetUnit: HaulingOrderDeliveryTargetUnitEnum;
};

export type HOAssignmentTracking = {
  driver: Pick<Driver, 'fullName'>;
  status: HaulingOrderDeliveryAssignmentStatusEnum;
  completedAt: number;
  goodsOut: number;
};

export type GetHOByTrackingCodeApiRequest = {
  trackingCode: string;
};

export type GetHOByTrackingCodeApiResponse = CommonApiResponse & {
  tracking: HaulingOrderTracking;
};

export type GetHODeliveryAssignmentByTrackingCodeApiRequest =
  CommonApiListParams & {
    trackingCode: string;
    deliveryId: string;
  };

export type GetHODeliveryAssignmentByTrackingCodeApiResponse =
  CommonApiResponse &
    CommonApiMetadata & {
      assignments: HOAssignmentTracking[];
    };
