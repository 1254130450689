import React, { PropsWithChildren } from 'react';
import tw, { styled, theme, TwStyle } from 'twin.macro';
import {
  Button,
  Icon,
  IconButton,
  LoadingIndicator,
  Paper,
  RootPortal,
  Text,
} from '../../atom';
import { ExtraStyle } from '../../Type.component';

// #region INTERFACES
type Props = React.ComponentProps<'div'> & {
  title?: string;
  renderTitle?: React.ReactNode;
  body?: React.ReactNode;
  renderCallAction?: React.ReactNode;
  callAction?: {
    isLoading?: boolean;
    containerStyle?: ExtraStyle;
    label: string | React.ReactNode;
    action: (data?: unknown) => void;
    disabled?: boolean;
  };
  cancelAction?: {
    label: string;
    action: () => void;
    disabled?: boolean;
  };
  actionButtonContainer?: TwStyle;
};
export type ModalComposeRootProps = PropsWithChildren<{
  containerStyle?: ExtraStyle;
  onOverlayClick?: () => void;
}>;
// #endregion

// #region STYLE
const LoadingContainer = tw.div`px-5 ml-2 flex`;
const CloseContainer = styled.div`
  & svg {
    width: 20px;
    height: 20px;

    & path {
      stroke-width: 4;
    }
  }
`;
const MobileContainer = tw(
  Paper,
)`box-border width[calc(100vw - 72px) ] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`;
const MobileActionContainer = tw.div`flex`;
const MobileButtonContainer = tw.div`flex flex-1`;
const MobileButton = tw(Button.Text)`flex-1 uppercase text-xs leading-4 py-4`;
const MobileButtonCancel = tw(MobileButton)`text-grey-two`;
const Container = tw(
  Paper,
)`px-8 py-7 box-border width[640px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 animate-fade-in`;
const ActionButtons = tw.div`flex justify-end space-x-5`;
const HeadContainer = tw.div`px-[22px] py-6 border-top-left-radius[6px] border-top-right-radius[6px] bg-beige-bg flex justify-between items-center`;
const HeadMetaContainer = tw.div`flex justify-between items-center`;
const ModalRoot = tw.div`w-screen h-screen fixed top-0 left-0 z-[1000] overflow-hidden`;
const Overlay = tw.div`bg-black bg-opacity-75 w-screen h-screen top-0 left-0 overflow-hidden`;
const TitleText = tw(Text.HeadingThree)`mb-4`;
const BodyText = tw(Text.BodyOne)`mb-8 text-grey-two`;
const CloseIconButton = tw(
  IconButton,
)`absolute right-0 -top-9 bg-white padding[2px]`;
const CloseIcon = tw(Icon.Close)`text-grey-two`;
const HeaderPrefix = tw.div`pr-2.5`;
// #endregion

export function Regular({
  title,
  body,
  callAction,
  cancelAction,
  children,
  onOverlayClick,
  containerStyle,
  ctaStyle,
  titleStyle,
  contentStyle,
  ...props
}: Props & {
  onOverlayClick?: () => void;
  containerStyle?: TwStyle;
  ctaStyle?: TwStyle;
  titleStyle?: TwStyle;
  contentStyle?: TwStyle;
}) {
  return (
    <RootPortal>
      <ModalRoot>
        <Container {...props} css={containerStyle}>
          <div css={contentStyle}>
            {title && <TitleText css={titleStyle}>{title}</TitleText>}
            {body && <BodyText>{body}</BodyText>}
            {children}
          </div>

          <ActionButtons css={ctaStyle}>
            {!!cancelAction && (
              <Button.Outlined
                type="button"
                onClick={cancelAction.action}
                disabled={cancelAction.disabled}
              >
                {cancelAction.label}
              </Button.Outlined>
            )}
            {!!callAction && (
              <Button.Solid
                type="button"
                css={callAction.containerStyle}
                onClick={callAction.action}
                disabled={callAction.disabled || callAction.isLoading}
              >
                {callAction.isLoading ? (
                  <LoadingContainer>
                    <LoadingIndicator
                      size="small"
                      color={theme`colors.white`}
                    />
                  </LoadingContainer>
                ) : (
                  callAction.label
                )}
              </Button.Solid>
            )}
          </ActionButtons>
        </Container>

        <Overlay
          // NOTE: based on eslint rule, we have to set role to non interactive element if we put an event to that element
          // but since there is no specific role for an "overlay" and we're not allowed to use abstarct role, none or presentation is the answer
          role="presentation"
          onClick={onOverlayClick}
        />
      </ModalRoot>
    </RootPortal>
  );
}

export function WithClose({
  title,
  body,
  callAction,
  cancelAction,
  children,
  onClose,
  onOverlayClick,
  containerStyle,
  ctaStyle,
  titleStyle,
  contentStyle,
  modalStyle,
  hideCloseButton,
  ...props
}: Props & {
  onClose: () => void;
  onOverlayClick?: () => void;
  hideCloseButton?: boolean;
  modalStyle?: false | '' | TwStyle;
  containerStyle?: false | '' | TwStyle;
  ctaStyle?: false | '' | TwStyle;
  titleStyle?: false | '' | TwStyle;
  contentStyle?: TwStyle;
}) {
  return (
    <RootPortal>
      <ModalRoot css={[modalStyle]}>
        <Container {...props} css={[containerStyle]}>
          {!hideCloseButton && (
            <CloseContainer>
              <CloseIconButton onClick={onClose}>
                <CloseIcon />
              </CloseIconButton>
            </CloseContainer>
          )}
          <div css={contentStyle} tw="contents">
            {title && <TitleText css={[titleStyle]}>{title}</TitleText>}
            {body && <BodyText>{body}</BodyText>}
            {children}
          </div>

          <ActionButtons css={[ctaStyle]}>
            {!!cancelAction && (
              <Button.Outlined
                type="button"
                onClick={cancelAction.action}
                disabled={cancelAction.disabled}
              >
                {cancelAction.label}
              </Button.Outlined>
            )}
            {!!callAction && (
              <Button.Solid
                type="button"
                css={callAction.containerStyle}
                onClick={callAction.action}
                disabled={callAction.disabled || callAction.isLoading}
              >
                {callAction.isLoading ? (
                  <LoadingContainer>
                    <LoadingIndicator
                      size="small"
                      color={theme`colors.white`}
                    />
                  </LoadingContainer>
                ) : (
                  callAction.label
                )}
              </Button.Solid>
            )}
          </ActionButtons>
        </Container>

        <Overlay role="presentation" onClick={onOverlayClick} />
      </ModalRoot>
    </RootPortal>
  );
}

export function Mobile({
  callAction,
  cancelAction,
  children,
  onClose,
  onOverlayClick,
  containerStyle,
  ctaStyle,
  titleStyle,
  ...props
}: Props & {
  onClose: () => void;
  onOverlayClick?: () => void;
  containerStyle?: false | '' | TwStyle;
  ctaStyle?: false | '' | TwStyle;
  titleStyle?: false | '' | TwStyle;
}) {
  return (
    <RootPortal>
      <ModalRoot>
        <MobileContainer {...props} css={[containerStyle]}>
          {children}
          <MobileActionContainer css={[ctaStyle]}>
            {!!cancelAction && (
              <MobileButtonContainer>
                <MobileButtonCancel
                  type="button"
                  onClick={cancelAction.action}
                  disabled={cancelAction.disabled}
                >
                  {cancelAction.label}
                </MobileButtonCancel>
              </MobileButtonContainer>
            )}
            {!!callAction && (
              <MobileButtonContainer>
                <MobileButton
                  type="button"
                  onClick={callAction.action}
                  disabled={callAction.disabled}
                >
                  {callAction.label}
                </MobileButton>
              </MobileButtonContainer>
            )}
          </MobileActionContainer>
        </MobileContainer>

        <Overlay role="presentation" onClick={onOverlayClick} />
      </ModalRoot>
    </RootPortal>
  );
}

export function WithHeader({
  title,
  renderTitle,
  body,
  callAction,
  renderCallAction,
  cancelAction,
  children,
  onClose,
  onOverlayClick,
  containerStyle,
  ctaStyle,
  headerContainerStyle,
  headerTitleStyle,
  headerPrefix,
  wrapperClassName,
  ...props
}: Props & {
  headerPrefix?: React.ReactNode;
  wrapperClassName?: string;
  onClose?: () => void;
  onOverlayClick?: () => void;
  containerStyle?: false | '' | TwStyle;
  ctaStyle?: false | '' | TwStyle;
  headerContainerStyle?: ExtraStyle;
  headerTitleStyle?: ExtraStyle;
}) {
  return (
    <RootPortal wrapperClassName={wrapperClassName}>
      <ModalRoot>
        <Container {...props} css={[containerStyle]}>
          {onClose && (
            <CloseContainer>
              <CloseIconButton
                onClick={() => {
                  if (callAction?.isLoading) return;
                  onClose();
                }}
              >
                <CloseIcon />
              </CloseIconButton>
            </CloseContainer>
          )}
          <HeadContainer css={headerContainerStyle}>
            <HeadMetaContainer>
              {headerPrefix && <HeaderPrefix>{headerPrefix}</HeaderPrefix>}
              {title && (
                <Text.HeadingThree css={headerTitleStyle}>
                  {title}
                </Text.HeadingThree>
              )}
              {renderTitle && renderTitle}
            </HeadMetaContainer>
            <ActionButtons css={[ctaStyle]}>
              {!!cancelAction && (
                <Button.Outlined
                  type="button"
                  onClick={cancelAction.action}
                  disabled={cancelAction.disabled}
                >
                  {cancelAction.label}
                </Button.Outlined>
              )}
              {!callAction && renderCallAction}
              {!!callAction && !renderCallAction && (
                <Button.Solid
                  type="button"
                  onClick={callAction.action}
                  disabled={callAction.disabled || callAction.isLoading}
                >
                  {callAction.isLoading ? (
                    <LoadingContainer>
                      <LoadingIndicator
                        size="small"
                        color={theme`colors.white`}
                      />
                    </LoadingContainer>
                  ) : (
                    callAction.label
                  )}
                </Button.Solid>
              )}
            </ActionButtons>
          </HeadContainer>

          {children}
        </Container>

        <Overlay role="presentation" onClick={onOverlayClick} />
      </ModalRoot>
    </RootPortal>
  );
}

// composition version
export function ComposeRoot({
  children,
  containerStyle,
  onOverlayClick,
}: ModalComposeRootProps) {
  return (
    <RootPortal>
      <ModalRoot>
        <Container css={containerStyle}>{children}</Container>

        <Overlay role="presentation" onClick={onOverlayClick} />
      </ModalRoot>
    </RootPortal>
  );
}
