import { Locale } from './locale.config';

const hoCreateLocaleConfig: Locale[] = [
  {
    en: 'Pickup Location is required.',
    id: 'Lokasi Pickup harus diisi.',
  },
  {
    en: 'Dropoff Location is required.',
    id: 'Lokasi Dropoff harus diisi.',
  },
  {
    en: 'Max 100 characters.',
    id: 'Maksimal 100 karakter.',
  },
  {
    en: 'Manual HO number cannot be started with char "HO-"',
    id: 'Nomor HO tidak dapat diawali dengan “HO-”',
  },
  {
    en: 'Max 999,999.99',
    id: 'Maksimal 999,999.99',
  },
  {
    en: 'Target Goods',
    id: 'Target Barang',
  },
  {
    en: 'Goods Target',
    id: 'Target Barang',
  },
  {
    en: 'Assigned Driver',
    id: 'Sopir Ditugaskan',
  },
  {
    en: 'Add Assignment',
    id: 'Tambah Tugas',
  },
  {
    en: 'Add Delivery',
    id: 'Tambah Pengiriman',
  },
  {
    en: 'Create Hauling Order',
    id: 'Buat Hauling Order',
  },
  {
    en: 'Goods List',
    id: 'Daftar Barang',
  },
  {
    en: 'Goods target is required.',
    id: 'Target barang harus diisi.',
  },
  {
    en: 'Create HJO',
    id: 'Buat HJO',
  },
  {
    en: 'Hauling order successfully created',
    id: 'Hauling order berhasil dibuat',
  },
  {
    en: 'Hauling Job Order successfully created',
    id: 'Hauling Job Order berhasil dibuat',
  },
  {
    en: 'Failed to create Hauling Job Order',
    id: 'Gagal membuat Hauling Job Order',
  },
  {
    en: 'Maximum value is 999.999,99.',
    id: 'Nilai maksimal 999.999,99.',
  },
  {
    en: 'Value should be more than 0.',
    id: 'Nilai harus lebih besar dari 0.',
  },
  {
    en: 'Finished Date',
    id: 'Waktu Selesai',
  },
  {
    en: 'Complete JO',
    id: 'Selesaikan JO',
  },
  {
    en: 'Order has been successfully completed',
    id: 'Order berhasil diselesaikan',
  },
  {
    en: 'Total Goods',
    id: 'Total Barang',
  },
  {
    en: 'Delivery',
    id: 'Pengiriman',
  },
  {
    en: 'Units',
    id: 'Unit',
  },
  {
    en: 'Goods In',
    id: 'Barang Diterima',
  },
  {
    en: 'Goods Out',
    id: 'Barang Diserahkan',
  },
  {
    en: 'Hauling Order Date',
    id: 'Tanggal Hauling Order',
  },
  {
    en: 'Order has been updated',
    id: 'Order berhasil diubah',
  },
  {
    en: 'You have maximum 24 hours to edit the JO after Completing the JO',
    id: 'Anda memiliki waktu maksimal 24 jam untuk mengubah JO setelah menyelesaikan JO',
  },
  {
    en: 'Add Job Order',
    id: 'Tambah Job Order',
  },
  {
    en: 'Job Order Number',
    id: 'Nomor Perintah Kerja',
  },
  {
    en: 'Job Order No',
    id: 'Nomor JO',
  },
  {
    en: 'Goods in is required.',
    id: 'Barang diterima harus diisi',
  },
  {
    en: "Value shouldn't be decimal value.",
    id: 'Nilai tidak boleh desimal.',
  },
  {
    en: 'Goods out is required.',
    id: 'Barang diserahkan harus diisi',
  },
];

export default hoCreateLocaleConfig;
